import { Route, Redirect} from 'react-router-dom';
import React, {Suspense, lazy, Fragment} from 'react';
import Loader from 'react-loaders'

const Files = lazy(() => import('../../Pages/Files'));
const AppMain = () => {

    return (
        <Fragment>

            {/* Components */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-rise"/>
                        </div>
                        <h6 className="mt-5">
                            Please wait while we load all the Components examples
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/files" component={Files}/>
            </Suspense>

            <Route exact path="/" render={() => (
                <Redirect to="/files"/>
            )}/>
        </Fragment>
    )
};

export default AppMain;